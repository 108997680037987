import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { PracticeSettings } from '../../../types/Practices/PracticeSettingsType';

export const usePracticeSettingsConnect = (practiceId?: string) => {
  const [practiceSettings, setPracticeSettings] = useState<PracticeSettings | undefined>(undefined);

  useEffect(() => {
    if (!practiceId) return;

    const sub = onSnapshot(doc(db, 'practices/' + practiceId + '/documents/practiceSettings'), (snapshot) => {
      if (snapshot?.data()) {
        setPracticeSettings(snapshot.data() as PracticeSettings);
      }
    });
    return () => {
      sub();
    };
  }, [practiceId]);

  return { practiceSettings };
};

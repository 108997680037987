import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LabeledSwitchButton } from '../../../core/components/buttons/LabeledSwitchButton';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { Divider } from '../../../core/components/SharedComponents';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { useHandleShowArticle } from '../../../core/utils/useHandleShowArticle';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';
import { Therapist } from '../../../types/Practices/TherapistType';
import { useHandlePracticeSettings } from '../../onboarding/utils/useHandlePracticeSettings';
import { TherapistCell } from './TherapistCell';

type Props = {
  therapists?: Therapist[];
};

export const ActiveTherapists = ({ therapists }: Props) => {
  const navigate = useNavigate();
  const { practiceId } = useParams();
  const { toggleIntercom } = useHandleShowArticle();

  const openAddTherapistModal = () => {
    navigate('add-therapist');
  };

  const { isSelfProfileCreationNotAllowed, saveOnToggle } = useHandlePracticeSettings(practiceId);
  return (
    <Container>
      <StyledH3>Aktive Therapeuten</StyledH3>
      <StyledDivider />
      <LabeledSwitchButton
        label="Therapeuten erlauben, eigene Profile anzulegen"
        isChecked={!isSelfProfileCreationNotAllowed}
        setIsChecked={saveOnToggle}
        onInfoClick={() => {
          toggleIntercom(10522027);
        }}
      />
      <StyledDivider />
      <RightAlignContainer>
        <TextButton
          onClick={openAddTherapistModal}
          text="Therapeuten-Profil hinzufügen"
          icon={{ name: 'Plus', color: colors.Primary }}
        />
      </RightAlignContainer>
      {therapists === undefined ? (
        <Skeleton height={50} count={3} />
      ) : (
        therapists?.map((therapist) => <TherapistCell key={therapist.id} therapist={therapist} />)
      )}
    </Container>
  );
};

const StyledDivider = styled(Divider)`
  background-color: ${hexWithOpacity(colors.Medium, 0.8)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
`;

const RightAlignContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: flex-end;
`;

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { colors } from '../../styles/colors';
import { hexWithOpacity } from '../utils/hexWithOpacity';
import { IconButton } from './buttons/IconButton';

type Props = {
  children: React.ReactNode;
  className?: string;
  hideCloseButton?: boolean;
  handleClose?: () => void;
};
export const BottomSheet = ({ children, className, hideCloseButton, handleClose }: Props) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const closeBottomSheet = () => {
    if (handleClose) {
      handleClose();
    } else {
      navigate('..');
    }
  };

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (overlayRef.current && event.target === overlayRef.current) {
      closeBottomSheet();
    }
  };

  const [fadeOpacity, setFadeOpacity] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const scrollTop = scrollRef.current.scrollTop;
        const opacity = Math.min(scrollTop / 100, 1);
        setFadeOpacity(opacity);
      }
    };

    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <Container onClick={handleOverlayClick} ref={overlayRef}>
      <ModalContent>
        {!hideCloseButton && <StyledIconButton iconName="Close" onClick={closeBottomSheet} stroke={colors.Medium} />}
        <FadeDiv $opacity={fadeOpacity} />
        <ScrollContainer ref={scrollRef} className={className}>
          {children}
        </ScrollContainer>
      </ModalContent>
    </Container>
  );
};

const slideAnimation = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: 12px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); // Darker background
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1001; // over the versionTag
  animation: fadeIn 0.4s ease-out;

  @keyframes fadeIn {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

const ModalContent = styled.div`
  background-color: ${colors.Background};
  border-radius: 20px 20px 0px 0;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 32vh;
  max-height: 95vh;
  margin-bottom: -2vh;
  animation: ${slideAnimation} 0.4s cubic-bezier(0.34, 1.2, 0.3, 1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  flex-grow: 1;
  padding-bottom: 100px;
`;

const FadeDiv = styled.div<{ $opacity: number }>`
  pointer-events: none;
  touch-action: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(${colors.Background}, ${hexWithOpacity(colors.Background, 0.8)}, transparent);
  height: 100px;
  width: 100%;
  opacity: ${({ $opacity }) => $opacity};
`;

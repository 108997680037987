import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components';
import { colors } from '../../../../styles/colors';
import { PracticeSubscriptionCreationData } from '../../../../types/WebUser/InvitationDataType';
import { useUserAccessRights } from '../../utils/useUserAccessRights';
import { useSubscriptionConfiguratorDataConnect } from '../utils/useSubscriptionConfiguratorDataConnect';
import { subscriptionPriceMonth } from './AdminInvitationSubscriptionCreator';

type Props = {
  practiceData: PracticeSubscriptionCreationData;
  changePracticeData: (value: PracticeSubscriptionCreationData) => void;
  schulungsterminDate?: string;
};

export const AdminPracticeSubscriptionSub = ({ practiceData, changePracticeData, schulungsterminDate }: Props) => {
  const { salesManagerAccess } = useUserAccessRights();
  const { subscriptionDiscounts } = useSubscriptionConfiguratorDataConnect();

  const cycleOptions = [
    ...(salesManagerAccess ? [{ value: 1, label: '1 Monat' }] : []),
    { value: 3, label: '3 Monate' },
    { value: 6, label: '6 Monate' },
    { value: 12, label: '12 Monate' },
    { value: 18, label: '18 Monate' },
    { value: 24, label: '24 Monate' },
  ];

  const data = practiceData.subscription;
  const startDetails = practiceData.startDetails;

  const changeDiscount = (value: number) => {
    const newPracticeData = cloneDeep(practiceData);
    if (value === 0) {
      newPracticeData.subscription = {
        ...newPracticeData.subscription,
        originalPrice: data.originalPrice,
        price: data.originalPrice,
        couponDetails: {
          percentage: 0,
        },
      };
    } else {
      newPracticeData.subscription = {
        ...newPracticeData.subscription,
        originalPrice: data.originalPrice,
        price: data.originalPrice * (1 - value / 100),
        couponDetails: {
          percentage: value,
        },
      };
    }
    if (!newPracticeData.subscription.secondPhase && value !== 0) {
      newPracticeData.subscription.cycleDetails.amountOfCycles =
        newPracticeData.subscription.cycleDetails.amountOfCycles ?? 1;
      newPracticeData.subscription.secondPhase = {
        originalPrice: subscriptionPriceMonth * 12,
        price: subscriptionPriceMonth * 12,
        cycleDetails: {
          monthCycle: 12,
        },
      };
    }
    changePracticeData(newPracticeData);
  };

  const changeCycle = (value: number) => {
    const newOriginalPrice = subscriptionPriceMonth * value;
    const newPrice = data.couponDetails?.percentage
      ? newOriginalPrice * (1 - data.couponDetails.percentage / 100)
      : newOriginalPrice;
    const newPracticeData = cloneDeep(practiceData);
    newPracticeData.subscription = {
      ...newPracticeData.subscription,
      originalPrice: newOriginalPrice,
      price: newPrice,
      cycleDetails: {
        ...newPracticeData.subscription.cycleDetails,
        monthCycle: value,
      },
    };
    changePracticeData(newPracticeData);
  };

  const changeStartImmediately = (value: boolean) => {
    const newPracticeData: PracticeSubscriptionCreationData = cloneDeep(practiceData);
    newPracticeData.startDetails = {
      startImmediately: value,
      ...(value ? {} : { startDate: initialStartDate.toISOString() }),
    };
    if (newPracticeData.oneTimePayments.branding) {
      newPracticeData.oneTimePayments.branding.payLater = false;
    }
    newPracticeData.oneTimePayments.onboardingFee.payLater = false;
    changePracticeData(newPracticeData);
  };

  const changeStartDate = (value: string) => {
    const newPracticeData = cloneDeep(practiceData);
    newPracticeData.startDetails = {
      startImmediately: false,
      startDate: value,
    };
    changePracticeData(newPracticeData);
  };

  const changeAmountOfCycles = (value: string) => {
    let newValue = value.replace(/\D/g, '');
    newValue = newValue.replace(/^0+/, '');
    if (newValue === '') newValue = '1';
    let numberValue = parseInt(newValue);
    const newPracticeData = cloneDeep(practiceData);
    newPracticeData.subscription = {
      ...newPracticeData.subscription,
      cycleDetails: {
        ...newPracticeData.subscription.cycleDetails,
        amountOfCycles: numberValue,
      },
    };
    changePracticeData(newPracticeData);
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const schulungsterminDateDate = schulungsterminDate ? new Date(schulungsterminDate) : undefined;
  const initialStartDate = schulungsterminDateDate ?? tomorrow;

  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  return (
    <TableRow>
      <TableCell>{'Servicegebühr'}</TableCell>
      <TableCell>{data.originalPrice} €</TableCell>
      <TableCell>
        <Select
          value={data?.couponDetails?.percentage ?? 0}
          onChange={(value) => changeDiscount(parseFloat(value.target.value))}
        >
          {subscriptionDiscounts.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </TableCell>

      <TableCell>
        <Select value={data?.cycleDetails.monthCycle} onChange={(value) => changeCycle(parseInt(value.target.value))}>
          {cycleOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        {!data.cycleDetails?.amountOfCycles ? (
          ' - '
        ) : (
          <TextInput
            value={data.cycleDetails.amountOfCycles ?? 1}
            onChange={(e) => changeAmountOfCycles(e.target.value)}
          />
        )}
      </TableCell>
      <TableCell>{`${data.price} € (mtl. ${data.price / data.cycleDetails.monthCycle} €)`}</TableCell>
      <TableCell>
        <Checkbox
          type="checkbox"
          checked={startDetails?.startImmediately}
          onChange={(event) => changeStartImmediately(event.target.checked)}
        />
      </TableCell>

      <TableCell>
        {!startDetails?.startDate ? (
          ' - '
        ) : (
          <DatePicker
            type="date"
            value={new Date(startDetails?.startDate).toISOString().split('T')[0]}
            min={tomorrow.toISOString().split('T')[0]}
            max={oneYearFromNow.toISOString().split('T')[0]}
            onChange={(event) => changeStartDate(event.target.value)}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const TableRow = styled.tr`
  border-bottom: 1px solid ${colors.Light};
  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  padding: 12px;
  color: ${colors.Dark};
`;

const TextInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid ${colors.Light};
  border-radius: 4px;
`;

const Select = styled.select`
  width: 100%;
  min-width: 140px;
  padding: 8px;
  border: 1px solid ${colors.Light};
  border-radius: 4px;
  background: ${colors.White};
`;

const Checkbox = styled.input`
  width: 32px;
  height: 32px;
  padding: 8px;
  border: 1px solid ${colors.Light};
  border-radius: 4px;
  border-color: ${colors.Medium};
  background: ${colors.White};
`;

const DatePicker = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid ${colors.Light};
  border-radius: 4px;
  background: ${colors.White};
`;

// Drawer.js
import styled from 'styled-components';
import { getChainBoughtBrandingPackage, getChainPractices, getIsChainBetaTester } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { DrawerCategory } from './DrawerCategory';

type Props = {
  isOpen: boolean;
  toggleDrawer: () => void;
};

export const Drawer = ({ isOpen, toggleDrawer }: Props) => {
  const practices = useMainStore(getChainPractices);
  const chainIsBetaTester = useMainStore(getIsChainBetaTester);
  const boughtBranding = useMainStore(getChainBoughtBrandingPackage);

  const categories = [
    {
      title: 'Home',
      items: [{ title: 'Übersicht', path: '/overview', isParent: false }],
    },
    {
      title: 'Standorte',
      items: [
        ...(practices?.map((practice) => ({
          title: practice.name,
          dropdown: practices.length > 1,
          path: `/practice-settings/` + practice.id,
          children: [
            ...[
              chainIsBetaTester
                ? {
                    title: 'Praxis-Pin',
                    path: `/practice-settings/` + practice.id + '/pin',
                  }
                : {
                    title: 'Praxislinks',
                    path: `/practice-settings/` + practice.id + '/links',
                  },
            ],

            {
              title: 'Praxisname',
              path: `/practice-settings/` + practice.id + '/name',
            },
            {
              title: 'Team',
              path: `/practice-settings/` + practice.id + '/team',
            },
            {
              title: 'Gutschrift',
              path: `/practice-settings/` + practice.id + '/payout',
            },
            {
              title: 'Partnergebühr',
              path: `/practice-settings/` + practice.id + '/invoice',
            },
            ...(boughtBranding
              ? [
                  {
                    title: 'App Branding',
                    path: `/practice-settings/` + practice.id + '/branding-settings',
                  },
                ]
              : []),
          ],
        })) ?? []),
      ],
    },
    {
      title: 'Einstellungen',
      items: [{ title: 'Konto', path: '/personal-data', isParent: false }],
    },
  ];
  return (
    <DrawerContainer $isOpen={isOpen}>
      <ContentContainer>
        <NavMenu>
          {categories.map((category) => (
            <DrawerCategory
              key={category.title}
              headline={category.title}
              items={category.items}
              toggleDrawer={toggleDrawer}
            />
          ))}
        </NavMenu>
        {/* <RecruitButton>Neu! Freunde werben</RecruitButton> */}
      </ContentContainer>
    </DrawerContainer>
  );
};

const DrawerContainer = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  height: 100vh;
  padding-top: 100px;
  left: ${(props) => (props.$isOpen ? '0' : '-250px')};
  width: 250px;
  background-color: ${colors.Background};
  transition: left 0.3s ease-in-out;
  overflow-x: hidden;
  z-index: 100;
  border-right: 1px solid ${colors.Light};
`;

const ContentContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const NavMenu = styled.div`
  flex-direction: column;
  display: flex;
  gap: 10px;
  padding-bottom: 30px;
`;

// const RecruitButton = styled(CustomButton)`
//   background-color: ${colors.Medium};
//   color: ${colors.White};
//   flex: 1;
//   width: 100%;
//   padding: 10px 15px;
//   border-radius: 10px;
//   flex-wrap: nowrap;
//   margin-top: auto;
// `;

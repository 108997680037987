import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { getChainId } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { ChainAuthDataType } from '../../../types/Practices/ChainAuthDataType';

export const useChainAuthDataConnect = () => {
  const chainId = useMainStore(getChainId);
  const [chainAuthData, setChainAuthData] = useState<ChainAuthDataType>();

  useEffect(() => {
    if (!chainId) return;
    const sub = onSnapshot(
      doc(db, 'practiceChains/' + chainId + '/chainDocuments/chainAuthData'),
      (chainAuthDataSnapshot) => {
        const chainAuthData = chainAuthDataSnapshot.data() as ChainAuthDataType;
        setChainAuthData(chainAuthData);
      },
    );
    return sub;
  }, [chainId]);

  return {
    chainAuthDataLoading: chainAuthData === undefined,
    pin: chainAuthData?.pin,
    pinChangeDate: chainAuthData?.pinChangeDate,
  };
};

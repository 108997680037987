import { saveAs } from 'file-saver';
import { doc, getDoc } from 'firebase/firestore';
import { useState } from 'react';
import { db } from '../../../../firebaseConfig';
import { useMainStore } from '../../../../store/mainStore';
import { Chain } from '../../../../types/Practices/ChainType';
import { PracticeContact } from '../../../../types/Practices/PracticeContactType';
import { PracticeInvoiceData } from '../../../../types/Practices/PracticeInvoiceDataType';
import { PracticePackageInfo } from '../../../../types/Practices/PracticePackageInfoType';
import { PracticePayout } from '../../../../types/Practices/PracticePayoutType';
import { PracticeStripeData } from '../../../../types/Practices/PracticeStripeDataType';
import { Practice } from '../../../../types/Practices/PracticeType';
import { PracticeUser } from '../../../../types/WebUser/PracticeUserType';

export const useDownloadPracticesCSV = (practices: Practice[]) => {
  const eventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadAllPracticesCSV = async (practiceIds?: string[]) => {
    if (!practices) return;
    let selectedPractices =
      !practiceIds || practiceIds.length === 0
        ? practices
        : practices.filter((practice) => practiceIds.includes(practice.id));
    setIsDownloading(true);
    try {
      const practicePromises: Promise<any>[] = [];
      for (const practice of selectedPractices) {
        const practiceDocumentsPath = 'practices/' + practice.id + '/documents';
        const practiceInvoicePromise = getDoc(doc(db, practiceDocumentsPath + '/practiceInvoiceData'));
        const practicePackageInfoPromise = getDoc(doc(db, practiceDocumentsPath + '/practicePackageInfo'));
        const practiceContactPromise = getDoc(doc(db, practiceDocumentsPath + '/practiceContact'));
        const practicePayoutPromise = getDoc(doc(db, practiceDocumentsPath + '/payoutData'));
        const practiceStripePromise = getDoc(doc(db, practiceDocumentsPath + '/practiceStripeData'));
        const practiceChainPromise = getDoc(doc(db, 'practiceChains/' + practice.chainId));
        const dashboardUserPromise = getDoc(doc(db, 'practiceUsers/' + practice.ownerId));

        const practicePromise = Promise.all([
          practiceInvoicePromise,
          practicePackageInfoPromise,
          practiceContactPromise,
          practicePayoutPromise,
          practiceStripePromise,
          practiceChainPromise,
          dashboardUserPromise,
        ]).then((values) => {
          const practiceInvoiceData = values[0].data() as PracticeInvoiceData;
          const practicePackageInfoData = values[1].data() as PracticePackageInfo;
          const practiceContactData = values[2].data() as PracticeContact;
          const practicePayoutData = values[3].data() as PracticePayout;
          const practiceStripeData = values[4].data() as PracticeStripeData;
          const chainData = values[5].data() as Chain;
          const userData = values[6].data() as PracticeUser;

          return {
            invoice: practiceInvoiceData,
            packageInfo: practicePackageInfoData,
            contact: practiceContactData,
            payout: practicePayoutData,
            stripe: practiceStripeData,
            chain: chainData,
            user: userData,
          };
        });
        practicePromises.push(practicePromise);
      }

      const practicesData = await Promise.all(practicePromises);

      const csvHeader = [
        'practice_name',
        'practice_id',
        'practice_isMigrated',
        'practice_ownerId',
        'practice_createdAt',
        'practice_onboardingCompletedDate',
        'practice_chainId',
        'practice_displayName',
        'practice_smsName',
        'practice_helpLink',
        'chain_id',
        'chain_name',
        'chain_ownerId',
        'chain_creationDate',
        'chain_hubspot_chainId',
        'chain_hubspot_contactId',
        'chain_hubspot_dealId',
        'chain_hubspot_chainHubspotName',
        'chain_hubspot_initialOwnerId',
        'chain_onboarding_invitationId',
        'chain_onboarding_paymentType',
        'chain_onboarding_flags_basicInfo',
        'chain_onboarding_flags_referral',
        'chain_onboarding_flags_practiceNames',
        'chain_onboarding_flags_practicesCompleted',
        'chain_onboarding_flags_feedback',
        'chain_onboarding_flags_practiceSMSNames',
        'chain_onboarding_flags_onboardingCompleted',
        'chain_onboarding_flags_clickedSocialMediaPage',
        'chain_onboarding_flags_clickedDownloadWebappPage',
        'chain_onboarding_flags_clickedShowIntercom',
        'chain_brandingPackage_bought',
        'chain_socialInfo_website',
        'chain_socialInfo_instagram',
        'chain_onboarding_flags_practices_index',
        'chain_onboarding_flags_practices_info',
        'chain_onboarding_flags_practices_referral',
        'chain_onboarding_flags_practices_payoutData',
        'chain_onboarding_flags_practices_therapists',
        'chain_onboarding_flags_practices_addedTherapists',
        'chain_onboarding_flags_practices_contactPerson',
        'chain_onboarding_flags_practices_starterPack',
        'chain_onboarding_flags_practices_invoiceData',
        'chain_onboarding_flags_practices_checkout',
        'chain_onboarding_flags_practices_contract',
        'chain_onboarding_flags_practices_completed',
        'chain_onboarding_flags_practices_brandingData',
        'user_id',
        'user_chainId',
        'user_firstName',
        'user_lastName',
        'user_email',
        'user_phoneNumber',
        'user_gender',
        'user_role',
        'user_creationDate',
        'user_hubspot_contactId',
        'user_hubspot_dealId',
        'user_hubspot_initialOwnerId',
        'invoice_practiceId',
        'invoice_address_name',
        'invoice_address_street',
        'invoice_address_cityCode',
        'invoice_address_city',
        'invoice_address_houseNumber',
        'invoice_taxId',
        'invoice_invoiceMail',
        'contact_firstName',
        'contact_lastName',
        'contact_email',
        'contact_phoneNumber',
        'contact_practiceId',
        'contact_chainId',
        'payout_practiceId',
        'payout_practiceName',
        'payout_taxId',
        'payout_contactMail',
        'payout_pricePerPatient',
        'payout_onboardingCopyIBanId',
        'payout_onboardingCopyAddressId',
        'payout_onboardingCompletedDate',
        'payout_hubspotPracticeId',
        'payout_address_payoutRecipient',
        'payout_address_additional',
        'payout_address_street',
        'payout_address_cityCode',
        'payout_address_city',
        'payout_address_houseNumber',
        'payout_account_firstName',
        'payout_account_lastName',
        'payout_account_accountHolder',
        'payout_account_iban',
        'payout_account_bic',
        'payout_account_bankName',
        'stripe_practiceId',
        'stripe_customerId',
        'stripe_subscriptionId',
        'stripe_latestSetupIntentEvent',
        'packageInfo_practiceId',
        'packageInfo_practiceName',
        'packageInfo_recipientName',
        'packageInfo_street',
        'packageInfo_houseNumber',
        'packageInfo_cityCode',
        'packageInfo_city',
        'packageInfo_starterPackage_shipmentId',
        'packageInfo_starterPackage_labelUrl',
        'packageInfo_starterPackage_sendInfoMailDate',
        'packageInfo_starterPackage_arrivedInfoMailDate',
        'packageInfo_onboardingCopy',
        'packageInfo_onboardingCompletedDate',
        'packageInfo_hubspotPracticeId',
      ].join(';');
      const csvRows = practicesData.map((data, index) =>
        [
          practices[index].name,
          practices[index].id,
          practices[index].isMigrated,
          practices[index].ownerId,
          practices[index].createdAt,
          practices[index].onboardingCompletedDate,
          practices[index].chainId,
          practices[index].displayName,
          practices[index].smsName,
          practices[index].helpLink,
          data.chain?.id,
          data.chain?.name,
          data.chain?.ownerId,
          data.chain?.creationDate,
          data.chain?.hubspot?.chainId,
          data.chain?.hubspot?.contactId,
          data.chain?.hubspot?.dealId,
          data.chain?.hubspot?.chainHubspotName,
          data.chain?.hubspot?.initialOwnerId,
          data.chain?.onboarding?.invitationId,
          data.chain?.onboarding?.onboardingPaymentType,
          data.chain?.onboarding?.onboardingFlags?.basicInfo,
          data.chain?.onboarding?.onboardingFlags?.referral,
          data.chain?.onboarding?.onboardingFlags?.practiceNames,
          data.chain?.onboarding?.onboardingFlags?.practicesCompleted,
          data.chain?.onboarding?.onboardingFlags?.feedback,
          data.chain?.onboarding?.onboardingFlags?.practiceSMSNames,
          data.chain?.onboarding?.onboardingFlags?.onboardingCompleted,
          data.chain?.onboarding?.onboardingFlags?.clickedSocialMediaPage,
          data.chain?.onboarding?.onboardingFlags?.clickedDownloadWebappPage,
          data.chain?.onboarding?.onboardingFlags?.clickedShowIntercom,
          data.chain?.brandingPackage?.bought,
          data.chain?.socialInfo?.website,
          data.chain?.socialInfo?.instagram,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.index,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.info,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.referral,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.payoutData,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.therapists,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.addedTherapists,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.contactPerson,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.starterPack,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.invoiceData,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.checkout,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.contract,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.completed,
          data.chain?.onboarding?.onboardingFlags?.practices?.[practices[index].id]?.brandingData,
          data.user?.id,
          data.user?.chainId,
          data.user?.privateInfo?.firstName,
          data.user?.privateInfo?.lastName,
          data.user?.privateInfo?.email,
          data.user?.privateInfo?.phoneNumber,
          data.user?.privateInfo?.gender,
          data.user?.accessControl?.role,
          data.user?.creationDate,
          data.user?.hubspotData?.contactId,
          data.user?.hubspotData?.dealId,
          data.user?.hubspotData?.initialOwnerId,
          data.invoice?.practiceId,
          data.invoice?.address?.name,
          data.invoice?.address?.street,
          data.invoice?.address?.cityCode,
          data.invoice?.address?.city,
          data.invoice?.address?.houseNumber,
          data.invoice?.taxId,
          data.invoice?.invoiceMail,
          data.contact?.firstName,
          data.contact?.lastName,
          data.contact?.email,
          data.contact?.phoneNumber,
          data.contact?.practiceId,
          data.contact?.chainId,
          data.payout?.practiceId,
          data.payout?.practiceName,
          data.payout?.taxId,
          data.payout?.contactMail,
          data.payout?.pricePerPatient,
          data.payout?.onboardingCopyIBanId,
          data.payout?.onboardingCopyAddressId,
          data.payout?.onboardingCompletedDate,
          data.payout?.hubspotPracticeId,
          data.payout?.payoutAddress?.payoutRecipient,
          data.payout?.payoutAddress?.additional,
          data.payout?.payoutAddress?.street,
          data.payout?.payoutAddress?.cityCode,
          data.payout?.payoutAddress?.city,
          data.payout?.payoutAddress?.houseNumber,
          data.payout?.payoutAccount?.firstName,
          data.payout?.payoutAccount?.lastName,
          data.payout?.payoutAccount?.accountHolder,
          data.payout?.payoutAccount?.iban,
          data.payout?.payoutAccount?.bic,
          data.payout?.payoutAccount?.bankName,
          data.stripe?.practiceId,
          data.stripe?.customerId,
          data.stripe?.subscriptionId,
          data.stripe?.latestSetupIntentEvent,
          data.packageInfo?.practiceId,
          data.packageInfo?.practiceName,
          data.packageInfo?.recipientName,
          data.packageInfo?.street,
          data.packageInfo?.houseNumber,
          data.packageInfo?.cityCode,
          data.packageInfo?.city,
          data.packageInfo?.starterPackage?.shipmentId,
          data.packageInfo?.starterPackage?.labelUrl,
          data.packageInfo?.starterPackage?.sendInfoMailDate,
          data.packageInfo?.starterPackage?.arrivedInfoMailDate,
          data.packageInfo?.onboardingCopy,
          data.packageInfo?.onboardingCompletedDate,
          data.packageInfo?.hubspotPracticeId,
        ].join(';'),
      );

      const csvContent = [csvHeader, ...csvRows].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'practices.csv');
      eventIndicator('success', 'Practices CSV downloaded successfully');
    } catch (error) {
      eventIndicator('error', 'Error downloading practices CSV');
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  return { downloadAllPracticesCSV, isDownloading };
};

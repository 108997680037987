import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import QRCode from 'react-qr-code';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IconButton } from '../../../core/components/buttons/IconButton';
import { PinDisplay } from '../../../core/components/pin/PinDisplay';
import { Divider } from '../../../core/components/SharedComponents';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { MOBILE_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';
import { ButtonText, H3, Small } from '../../../styles/textStyles';
import { useChainAuthDataConnect } from '../utils/useChainAuthDataConnect';
import { useHandlePracticeLinks } from '../utils/useHandlePracticeLinks';

type PracticeLinkContainerProps = {
  practiceId: string;
};

export const PracticeLink = ({ practiceId }: PracticeLinkContainerProps) => {
  const navigate = useNavigate();

  const [isPinVisible, setIsPinVisible] = useState(false);

  const { pin, chainAuthDataLoading, pinChangeDate } = useChainAuthDataConnect();
  const { loadingPractice, practiceLink, practiceName, isBetaTester, copyLink, openLink } =
    useHandlePracticeLinks(practiceId);

  const togglePinVisibility = () => setIsPinVisible(!isPinVisible);
  const pathname = window.location.pathname;
  const openPracticePinSettings = () => {
    if (pathname.includes('overview')) {
      navigate(`${practiceId}/pin-settings`);
    } else navigate(`pin-settings`);
  };

  const oneDay = 24 * 60 * 60 * 1000;

  const remainingHours = Math.max(
    Math.floor((new Date(pinChangeDate ?? '').getTime() + oneDay - new Date().getTime()) / 1000 / 60 / 60),
    1,
  );
  let remainingText = `Dieser Pin ist noch ${remainingHours} Stunden gültig.`;
  if (remainingHours <= 1) {
    remainingText = `Dieser Pin ist noch eine Stunde gültig.`;
  }

  return (
    <>
      {!practiceLink ? (
        <Skeleton width={'100%'} height={200} borderRadius={20} />
      ) : (
        <Container>
          {loadingPractice ? (
            <>
              <Skeleton width={120} height={120} />
              <div style={{ width: '100%' }}>
                <Skeleton height={20} count={2} />
              </div>
            </>
          ) : (
            <OuterContainer>
              <QRCodeContainer>
                <div>
                  <QRCode size={120} value={practiceLink} viewBox={`0 0 120 120`} />
                </div>
                <InnerContainer>
                  <LinkContainer>
                    <H3>{`Praxislink Standort ${practiceName}`}</H3>
                    <StyledSmall onClick={openLink}>{practiceLink}</StyledSmall>
                  </LinkContainer>
                  <LinkButtonsContainer>
                    <StyledButtonText onClick={openLink}>Öffnen</StyledButtonText>
                    <StyledButtonText onClick={copyLink}>Link Kopieren</StyledButtonText>
                  </LinkButtonsContainer>
                  <InfoButton iconName={'Info'} onClick={() => {}} stroke={colors.Medium} />
                </InnerContainer>
              </QRCodeContainer>
              {isBetaTester && (
                <>
                  <StyledDivider />
                  <PinContainer>
                    <div>
                      <div>
                        {chainAuthDataLoading || !pin ? (
                          <SkeletonContainer>
                            {[1, 2, 3, 4, 5, 6].map((_, index) => (
                              <Skeleton width={22} height={40} key={index} />
                            ))}
                          </SkeletonContainer>
                        ) : (
                          <PinDisplay pin={pin} isNotVisible={!isPinVisible} />
                        )}
                        <StyledButtonText onClick={togglePinVisibility}>
                          Praxis-PIN {isPinVisible ? 'ausblenden' : 'einblenden'}
                        </StyledButtonText>
                      </div>
                      <PinValidityText>{remainingText}</PinValidityText>
                    </div>
                    <StyledButtonText onClick={openPracticePinSettings}>Praxis-PIN Einstellungen</StyledButtonText>
                  </PinContainer>
                </>
              )}
            </OuterContainer>
          )}
        </Container>
      )}
      <Outlet />
    </>
  );
};

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
`;

const PinContainer = styled.div`
  display: flex;
  flex-direction: row;
  > div:first-child {
    > div:first-child {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      flex-wrap: wrap;
      @media (max-width: ${MOBILE_BREAKPOINT}) {
        flex-direction: column;
        gap: 0;
      }
    }
    display: flex;
    flex-direction: column;
    gap: 5px;
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      align-items: center;
    }
  }
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  gap: 20px;
  flex-wrap: wrap;
`;

const PinValidityText = styled(Small)`
  color: ${colors.Primary};
`;

const StyledDivider = styled(Divider)`
  background-color: ${hexWithOpacity(colors.Medium, 0.9)};
`;

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${hexWithOpacity(colors.Medium, 0.1)};
  padding: 20px;
  border-radius: 20px;
`;

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
  }
  > div:first-child {
    height: auto;
    margin: 0 auto;
    max-width: 120px;
    width: 100%;
    > :first-child {
      width: 100%;
      height: auto;
    }
  }
`;

const InfoButton = styled(IconButton)`
  position: absolute;
  top: -10px;
  right: -10px;
`;

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  gap: 20px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
`;

const StyledSmall = styled(Small)`
  color: ${colors.Medium};
  text-decoration: underline;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 365px;
  cursor: pointer;
`;

const LinkButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    align-self: center;
  }
`;

const StyledButtonText = styled(ButtonText)`
  text-decoration: underline;
  cursor: pointer;
  padding: 15px 0;
`;

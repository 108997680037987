import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { H2 } from '../../styles/textStyles';
import { useOnboardingPracticeInfo } from '../onboarding/utils/useOnboardingPracticeInfo';
import { MainScreenContainer } from '../overview/components/MainScreenComponent';
import { PracticeLink } from './components/PracticeLink';

export const PracticeSettingsPinScreen = () => {
  const { practiceName, practiceId } = useOnboardingPracticeInfo();

  return (
    <MainScreenContainer>
      <Container>
        <StyledH2>Praxis-Pin für den Standort {practiceName}</StyledH2>
        <PracticeLink practiceId={practiceId ?? ''} />
      </Container>
    </MainScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 720px;
  padding-bottom: 200px;
`;

const StyledH2 = styled(H2)`
  color: ${colors.Dark};
`;

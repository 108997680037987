import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const Divider = styled.div`
  align-self: center;
  width: calc(100% - 10px);
  height: 2px;
  background-color: ${colors.White};
  opacity: 0.1;
  margin: 10px 0;
`;

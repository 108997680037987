import { StateCreator } from 'zustand';
import { Chain } from '../types/Practices/ChainType';
import { State } from './mainStore';

export type ChainState = {
  chainData?: Chain;
  setChainData: (chainData: Chain) => void;
  resetChainState: () => void;
};

const initialState = {
  chainData: undefined,
};

export const createChainSlice: StateCreator<ChainState> = (set) => ({
  ...initialState,
  setChainData: (chainData) => set({ chainData }),
  resetChainState: () => set(initialState),
});

export const getChainData = (state: ChainState) => state.chainData;
export const getChainPractices = (state: ChainState) => state.chainData?.practices;
export const getIncompleteChainPractices = (state: ChainState) => {
  return state.chainData?.practices?.filter(
    (p) => !state.chainData?.onboarding?.onboardingFlags?.practices?.[p.id]?.completed,
  );
};
export const getChainPracticesWithoutPayment = (state: ChainState) => {
  return state.chainData?.practices?.filter(
    (p) => !state.chainData?.onboarding?.onboardingFlags?.practices?.[p.id]?.checkout,
  );
};
export const getIncompleteChainPracticesWithPayment = (state: ChainState) => {
  return getIncompleteChainPractices(state)?.filter(
    (p) => state.chainData?.onboarding?.onboardingFlags?.practices?.[p.id]?.checkout,
  );
};
export const getChainPracticesAmount = (state: ChainState) => state.chainData?.practices?.length ?? 0;
export const getChainPractice = (state: ChainState, practiceId?: string) =>
  state.chainData?.practices?.find((p) => p.id === practiceId);
export const getChainPracticeIndex = (state: ChainState, practiceId?: string) =>
  state.chainData?.practices?.findIndex((p) => p.id === practiceId);
export const getChainPracticeName = (state: ChainState, practiceId?: string) =>
  getChainPractice(state, practiceId)?.name;
export const getChainOnboardingPaymentType = (state: ChainState) => state.chainData?.onboarding?.onboardingPaymentType;
export const getOnboardingFlags = (state: ChainState) => state.chainData?.onboarding?.onboardingFlags;
export const getChainPreviousPracticeId = (state: ChainState, practiceId?: string) => {
  const incompletePractices = getIncompleteChainPractices(state);
  const index = incompletePractices?.findIndex((p) => p.id === practiceId);
  return index ? incompletePractices?.[index - 1]?.id : undefined;
};
export const getChainNextPracticeId = (state: ChainState, practiceId?: string) => {
  const incompletePractices = getIncompleteChainPractices(state);
  const index = incompletePractices?.findIndex((p) => p.id === practiceId);
  return index !== undefined ? incompletePractices?.[index + 1]?.id : undefined;
};
export const getChainOnboardingPracticeInfo = (state: State, practiceId?: string) => {
  const practiceName = getChainPracticeName(state, practiceId);
  const practiceIndex = getChainPracticeIndex(state, practiceId);
  const previousPracticeId = getChainPreviousPracticeId(state, practiceId);
  const nextPracticeId = getChainNextPracticeId(state, practiceId);
  const paymentType = getChainOnboardingPaymentType(state);
  const isAddNewPractice = getIsAddNewPractice(state);
  const practicesAmount = getChainPracticesAmount(state);
  const chainId = state.chainData?.id;
  return {
    practiceName,
    practiceIndex,
    previousPracticeId,
    nextPracticeId,
    paymentType: isAddNewPractice ? 'different' : paymentType,
    chainId,
    practicesAmount,
  };
};
export const getOnboardingCompleted = (state: ChainState) =>
  state.chainData?.onboarding?.onboardingFlags?.onboardingCompleted;
export const getChainInvitationId = (state: ChainState) => state.chainData?.onboarding?.invitationId;
export const getIsAddNewPractice = (state: ChainState) =>
  state.chainData?.onboarding?.onboardingFlags?.onboardingCompleted ?? false;
export const getChainName = (state: ChainState) => state.chainData?.name;
export const getChainId = (state: ChainState) => state.chainData?.id;
export const getAppBrandingCompleted = (state: ChainState, practiceId: string) =>
  state.chainData?.onboarding?.onboardingFlags?.practices?.[practiceId]?.brandingData;
export const getChainBoughtBrandingPackage = (state: ChainState) => state.chainData?.brandingPackage?.bought;
export const getPracticesBrandingCompleted = (state: ChainState) => {
  return state.chainData?.practices?.every((p) => getAppBrandingCompleted(state, p.id)) ?? false;
};

export const getIsChainBetaTester = (state: ChainState) => state.chainData?.isBetaTester;

import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../styles/colors';
import { hexWithOpacity } from '../../utils/hexWithOpacity';

type Props = {
  value: string;
  error?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  small?: boolean;
  isNotVisible?: boolean;
};

export const PinInputField = (props: Props) => {
  return (
    <StyledInput
      type="text"
      value={props.isNotVisible ? '*' : props.value}
      $error={props.error}
      $highlighted={props.highlighted ?? false}
      $disabled={props.disabled ?? false}
      aria-disabled={true}
      $small={props.small}
      readOnly
    />
  );
};

const shake = keyframes`
  0%, 100% { transform: translateX(0); }
  15% { transform: translateX(-2px); }
  30% { transform: translateX(2px); }
  45% { transform: translateX(-2px); }
  60% { transform: translateX(2px); }
  75% { transform: translateX(-2px); }
  90% { transform: translateX(2px); }
`;

const StyledInput = styled.input<{ $error?: boolean; $highlighted: boolean; $disabled: boolean; $small?: boolean }>`
  pointer-events: none;
  width: ${(props) => (props.$small ? '22px' : '40px')};
  height: ${(props) => (props.$small ? '40px' : '72px')};
  border-radius: 8px;
  padding: 0;
  text-align: center;
  font:
    ${(props) => (props.$small ? '500 18px/24px' : '500 32px/32px')} 'Ubuntu',
    sans-serif;
  color: ${(props) => (props.$disabled ? colors.White : colors.Medium)};
  background-color: ${(props) =>
    props.$disabled ? hexWithOpacity(colors.White, 0.2) : hexWithOpacity(colors.White, 0.9)};
  box-shadow: none;
  transition: border-color 0.2s ease;
  animation: ${(props) =>
    props.$error
      ? css`
          ${shake} 0.5s ease-in-out
        `
      : 'none'};
  border: 3px solid ${(props) => (props.$highlighted ? colors.Primary : props.$error ? colors.LightRed : 'transparent')};
  outline: none;
`;

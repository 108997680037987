import styled from 'styled-components';
import { useMainStore } from '../../store/mainStore';
import { DESKTOP_BREAKPOINT } from '../../styles/breakpoints';
import { Body } from '../../styles/textStyles';
import { CustomButton } from './buttons/CustomButton';

type VersionTagProps = {
  className?: string;
};

export const VersionTag = (props: VersionTagProps) => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(import.meta.env.VITE_VERSION);
      setEventIndicator('success', 'In die Zwischenablage kopiert');
    } catch (error) {
      console.error('Failed to copy to clipboard', error);
      setEventIndicator('error', 'Fehler beim Kopieren');
    }
  };
  const version = import.meta.env.VITE_VERSION;
  return (
    <Container className={props.className}>
      <CustomButton onClick={copyToClipboard}>
        <StyledBody>{'v' + version}</StyledBody>
      </CustomButton>
    </Container>
  );
};

const Container = styled.div`
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 30px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

const StyledBody = styled(Body)`
  opacity: 0.2;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.1;
  }
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    opacity: 0.5;
  }
`;

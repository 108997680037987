import { useEffect } from 'react';
import styled from 'styled-components';
import { PinInputField } from './PinInputField';

type Props = {
  pin: string;
  isNotVisible?: boolean;
};

export const PinDisplay = (props: Props) => {
  const { pin } = props;
  const pinArray = pin.split('');
  useEffect(() => {
    document.getElementById('firstPinInput')?.focus();
  }, []);

  return (
    <Container>
      <PinContainer>
        {pinArray.map((value, index) => (
          <PinInputField key={index} value={value} small isNotVisible={props.isNotVisible} />
        ))}
      </PinContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: column;
`;

const PinContainer = styled.div`
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
`;

import { useState } from 'react';
import styled from 'styled-components';

import { collection, deleteDoc, doc, getDocs, query, where, writeBatch } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { LoaderFunction, LoaderFunctionArgs, Navigate, useLoaderData, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { IconButton } from '../../../core/components/buttons/IconButton';
import { LabeledSwitchButton } from '../../../core/components/buttons/LabeledSwitchButton';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { InfoText } from '../../../core/components/text/InfoText';
import { Leaves, setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import { useHandleShowArticle } from '../../../core/utils/useHandleShowArticle';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { getUserChainId, getUserFirstName, getUserLastName } from '../../../store/userSlice';
import { colors } from '../../../styles/colors';
import { OnboardingFlags } from '../../../types/Practices/ChainType';
import { Therapist } from '../../../types/Practices/TherapistType';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { PracticeTherapistInformation } from '../components/PracticeTherapistInformation';
import { useHandleOnSubmitKeyPress } from '../utils/useHandleOnSubmitKeyPress';
import { useHandlePracticeSettings } from '../utils/useHandlePracticeSettings';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

export const practiceTherapistsDataLoader: LoaderFunction = async ({ params }: LoaderFunctionArgs) => {
  const practiceId = params.practiceId;
  if (!practiceId) return null;
  const therapistsSnapshot = await getDocs(query(collection(db, 'therapists'), where('practiceId', '==', practiceId)));
  const therapistsData = therapistsSnapshot.docs
    .map((doc) => doc.data() as Therapist)
    .sort((a, b) => a.creationUnix - b.creationUnix);
  return !therapistsSnapshot.empty ? therapistsData : null;
};
export const OnboardingPracticeTherapistsURL = 'practice-therapists/:practiceId';

export const OnboardingPracticeTherapistsScreen = () => {
  const { t } = useTranslation();
  const { practiceId, practiceIndex, practiceName, isMigration } = useOnboardingPracticeInfo();
  const { isSelfProfileCreationNotAllowed, toggleSelfProfileCreationAllowed, updateSettings } =
    useHandlePracticeSettings(practiceId);
  const { toggleIntercom } = useHandleShowArticle();

  const initialTherapists = useLoaderData() as null | Therapist[];
  const chainId = useMainStore(getUserChainId);
  const ownerFirstName = useMainStore(getUserFirstName);
  const ownerLastName = useMainStore(getUserLastName);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [therapists, setTherapists] = useState<
    Array<{ firstName: string; lastName: string; id: string; creationUnix: number }>
  >(
    initialTherapists ?? [
      ...(practiceIndex === 0
        ? [{ firstName: ownerFirstName ?? '', lastName: ownerLastName ?? '', id: uuidv4(), creationUnix: Date.now() }]
        : []),
      { firstName: '', lastName: '', id: uuidv4(), creationUnix: Date.now() },
    ],
  );

  const handleAddTherapist = () => {
    setTherapists((prev) => [...prev, { firstName: '', lastName: '', id: uuidv4(), creationUnix: Date.now() }]);
  };

  const handleBack = () => {
    navigate('../practice-payout-data');
  };

  const handleRemoveTherapist = (index: number) => {
    const newTherapists = [...therapists];
    newTherapists.splice(index, 1);
    setTherapists(newTherapists);
    const removeTherapist = therapists[index];
    deleteDoc(doc(db, 'therapists/' + removeTherapist.id));
  };

  const changeTherapist = (index: number, value: { firstName: string; lastName: string }) => {
    const newTherapists = [...therapists];
    newTherapists[index].firstName = value.firstName;
    newTherapists[index].lastName = value.lastName;
    setTherapists(newTherapists);
  };

  const handleNext = async (skip?: boolean) => {
    if (!practiceId) return;
    setLoading(true);
    const batch = writeBatch(db);
    therapists
      .filter((e) => e.firstName !== '' && e.lastName !== '')
      .forEach((therapist, index) => {
        batch.set(
          doc(db, 'therapists/' + therapist.id),
          {
            firstName: therapist.firstName,
            lastName: therapist.lastName,
            id: therapist.id,
            practiceId: practiceId,
            chainId,
            index,
            creationUnix: therapist.creationUnix,
          },
          { merge: true },
        );
      });
    await updateSettings();
    try {
      let newFlags: Leaves<OnboardingFlags>[] = [`practices.${practiceId}.therapists`];
      if (!skip) {
        newFlags.push(`practices.${practiceId}.addedTherapists`);
      }
      console.log('newFlags', newFlags);
      await batch.commit();
      await setChainOnboardingFlag(newFlags, 'practices-therapists');
      navigate(`../practice-contact`);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useHandleOnSubmitKeyPress(handleAddTherapist);

  if (!practiceId || practiceIndex === undefined || isMigration) {
    return <Navigate to="../../practices-names" />;
  }

  const buttonDisabled =
    therapists.some(
      (therapist) => (therapist.firstName && !therapist.lastName) || (!therapist.firstName && therapist.lastName),
    ) ||
    therapists[0].firstName === '' ||
    therapists[0].lastName === '';
  return (
    <OnboardingScreenContainer
      handleBack={handleBack}
      nextButton={{
        disabled: buttonDisabled,
        onClick: handleNext,
        loading: loading,
      }}
      textButton={{
        text: t('ONBOARDING-PRACTICE-THERAPISTS-SCREEN-SKIP'),
        onClick: () => handleNext(true),
      }}
    >
      <InfoText
        preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName })}
        headline={t('ONBOARDING-PRACTICE-THERAPISTS-SCREEN-TITLE')}
        text={[t('ONBOARDING-PRACTICE-THERAPISTS-SCREEN-SUBTITLE')]}
      />
      <LabeledSwitchButton
        label="Therapeuten erlauben, eigene Profile anzulegen"
        isChecked={!isSelfProfileCreationNotAllowed}
        setIsChecked={toggleSelfProfileCreationAllowed}
        onInfoClick={() => {
          toggleIntercom(10522027);
        }}
      />
      <TherapistsContainer>
        {therapists.map((therapist, index) => (
          <TherapistContainer $isFirst={index === 0} key={'Therapist' + therapist.id}>
            <PracticeTherapistInformation
              key={index}
              fields={{ firstName: therapist.firstName, lastName: therapist.lastName }}
              setFields={(value) => changeTherapist(index, value)}
            />
            {index > 0 && (
              <IconButton
                iconName="Close"
                onClick={() => handleRemoveTherapist(index)}
                size={24}
                stroke={colors.Dark}
              />
            )}
          </TherapistContainer>
        ))}
        <AddButtonContainer>
          <TextButton
            text={t('ONBOARDING-PRACTICE-THERAPISTS-SCREEN-ADD-THERAPIST')}
            icon={{ name: 'Plus', size: 24, color: colors.Primary }}
            onClick={handleAddTherapist}
          />
        </AddButtonContainer>
      </TherapistsContainer>
    </OnboardingScreenContainer>
  );
};
const TherapistContainer = styled.div<{ $isFirst?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-right: ${(props) => (props.$isFirst ? '46px' : '0px')};
`;

const TherapistsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AddButtonContainer = styled.div`
  align-self: flex-end;
`;

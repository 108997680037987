import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunction, LoaderFunctionArgs, Navigate, useNavigate } from 'react-router-dom';
import { LabeledSwitchButton } from '../../../core/components/buttons/LabeledSwitchButton';
import { InfoText } from '../../../core/components/text/InfoText';
import { setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import { db } from '../../../firebaseConfig';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { StarterPackInformation } from '../components/StarterPackInformation';

import styled from 'styled-components';
import { PracticePackageInfo } from '../../../types/Practices/PracticePackageInfoType';
import { useHandleTransferStarterPackageData } from '../utils/useHandleTransferStarterPackageData';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

export const practiceStarterPackageLoader: LoaderFunction = async ({ params }: LoaderFunctionArgs) => {
  const practiceId = params.practiceId;
  if (!practiceId) return null;
  const practiceSnapshot = await getDoc(doc(db, 'practices/' + practiceId + '/documents/practicePackageInfo'));
  const practiceData = practiceSnapshot?.data() as undefined | PracticePackageInfo;
  return practiceData ?? null;
};

export const OnboardingPracticeStarterPackageScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isMigration, practiceId, practiceIndex, practiceName, nextPracticeId, paymentType } =
    useOnboardingPracticeInfo();

  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const { toggleActive, handleToggle, fields, setFields } = useHandleTransferStarterPackageData(
    practiceId ?? '',
    setCurrentStep,
  );

  const handleNext = async () => {
    try {
      if (!practiceId) return;
      let data = {
        practiceId: practiceId,
        practiceName: fields.practiceName,
        recipientName: fields.recipientName,
        street: fields.street,
        houseNumber: fields.houseNumber,
        cityCode: fields.cityCode,
        city: fields.city,
        onboardingCopy: toggleActive,
      } as PracticePackageInfo;
      if (isMigration) {
        data = {
          ...data,
          starterPackage: {
            deliveryState: 'completed',
            arrivedInfoMailDate: new Date().toISOString(),
            labelUrl: '-',
            sendInfoMailDate: new Date().toISOString(),
            shipmentId: '-',
          },
        };
      }

      await setDoc(doc(db, 'practices/' + practiceId + '/documents/practicePackageInfo'), data, { merge: true });
      await setChainOnboardingFlag(`practices.${practiceId}.starterPack`, 'practices-starterPack');
      if (practiceIndex === 0 && nextPracticeId) {
        if (isMigration) {
          navigate(`../../${nextPracticeId}/practice-data-info`);
        } else {
          navigate('../payment-type');
        }
      } else {
        if ((isMigration || paymentType === 'same') && nextPracticeId) {
          navigate(`../../${nextPracticeId}/practice-data-info`);
        } else {
          if (isMigration) {
            navigate('/onboarding/feedback');
          } else {
            navigate('../invoice-address');
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('../practice-contact');
  };

  if (!practiceId) {
    return <Navigate to="../../practices-names" />;
  }

  return (
    <OnboardingScreenContainer
      handleBack={handleBack}
      nextButton={{
        currentStep: currentStep,
        numberOfSteps: 5,
        onClick: handleNext,
        loading: loading,
      }}
    >
      <InfoText
        preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName })}
        headline={isMigration ? 'Praxis Adresse' : t('ONBOARDING-PRACTICE-STARTER-PACK-SCREEN-TITLE')}
        text={isMigration ? undefined : [t('ONBOARDING-PRACTICE-STARTER-PACK-SCREEN-SUBTITLE')]}
      />
      <Container>
        <LabeledSwitchButton
          label={t('ONBOARDING-PRACTICE-STARTER-PACK-SCREEN-USE-BILLING-ADDRESS')}
          isChecked={toggleActive}
          setIsChecked={handleToggle}
        />
        <StarterPackInformation setCurrentStep={setCurrentStep} fields={fields} setFields={setFields} />
      </Container>
    </OnboardingScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ActionMenu } from '../../../../core/components/ActionMenu';
import { RadioButton } from '../../../../core/components/buttons/RadioButton';
import { TextButton } from '../../../../core/components/buttons/TextButton';
import { FullScreenContainer } from '../../../../core/components/container/FullScreenContainer';
import { colors } from '../../../../styles/colors';
import { H1, H3, H4 } from '../../../../styles/textStyles';
import { useAllPractices } from '../../connectUtils/useAdminAllPractices';
import { AdminPracticeStatusLine } from '../components/AdminPracticeStatusLine';
import { useDownloadPackageLabels } from '../utils/useDownloadPackageLabels';
import { useDownloadPracticesCSV } from '../utils/useDownloadPracticesCSV';

export const AdminPracticesOverviewScreen = () => {
  const navigate = useNavigate();
  const [selectedPractices, setSelectedPractices] = useState<string[]>([]);

  const { downloadAllLabels, isDownloading } = useDownloadPackageLabels();
  const practices = useAllPractices();
  const { downloadAllPracticesCSV, isDownloading: isDownloadingCSV } = useDownloadPracticesCSV(practices ?? []);

  const selectBetaPractices = () => {
    const testIds = [
      ...new Set([
        ...(practices?.filter((e) => e.isBetaTester).map((e) => e.id) ?? []),
        'recezP6dDNVjg9tjL',
        'recll1hABEEz1KnnB',
        'recmzYPMzmmNABCuk',
        'recNqnuF05zRg6PAD',
        'recaZycTgCafX00x0',
        'recTkCYv3nHQgr02m',
        'recFAwdZhxEITTXDn',
        'receZOUTC8OHPqHWA',
      ]),
    ];
    setSelectedPractices(testIds);
  };
  const actions = [
    {
      label: 'Download Package Labels',
      onClick: downloadAllLabels,
      disabled: isDownloading,
    },
    {
      label: 'Download Practices CSV',
      onClick: downloadAllPracticesCSV,
      disabled: !practices || isDownloadingCSV,
    },
    {
      label: 'Select Beta Practices',
      onClick: selectBetaPractices,
      disabled: !practices,
    },
  ];
  const migrationPractices = practices?.filter((e) => e.isMigrated);
  const newPractices = practices?.filter((e) => !e.isMigrated);

  const handleSelectPractice = (practiceId: string) => {
    if (selectedPractices.includes(practiceId)) {
      setSelectedPractices(selectedPractices.filter((id) => id !== practiceId));
    } else {
      setSelectedPractices([...selectedPractices, practiceId]);
    }
  };

  const selectAllPractices = () => {
    if (selectedPractices.length === practices?.length) {
      setSelectedPractices([]);
    } else {
      setSelectedPractices(practices?.map((e) => e.id) ?? []);
    }
  };

  const openSendMailModal = () => {
    navigate('send-group-mail', { state: { practices: selectedPractices } });
  };

  const downloadSelectedPracticesCSV = () => {
    downloadAllPracticesCSV(selectedPractices);
  };

  const downloadSelectedLabels = () => {
    downloadAllLabels(selectedPractices);
  };

  return (
    <FullScreenContainer>
      <Container>
        <ToolBar>
          <SelectedPracticesActions>
            <H3>{`${selectedPractices.length} Praxen ausgewählt`}</H3>
            <TextButton onClick={openSendMailModal} disabled={selectedPractices?.length === 0} text="Mail Versenden" />
            <TextButton
              onClick={downloadSelectedPracticesCSV}
              disabled={selectedPractices?.length === 0}
              text="Download CSV"
            />
            <TextButton
              onClick={downloadSelectedLabels}
              disabled={selectedPractices?.length === 0}
              text="Download Labels"
            />
          </SelectedPracticesActions>
          <ActionMenuContainer>
            <H3>Actions</H3>
            <ActionMenu actions={actions} />
          </ActionMenuContainer>
        </ToolBar>

        <StyledH1>{`Praxen Overview (${practices?.length} = ${newPractices?.length ?? 0} Portal + ${migrationPractices?.length ?? 0} Migration)`}</StyledH1>
        <Headline>
          <Index>
            <RadioButton checked={selectedPractices.length === practices?.length} onPress={selectAllPractices} />
          </Index>
          <Index>Index</Index>
          <Date>Erstellt am</Date>
          <Name>Name</Name>
          <Date>Status</Date>
          <H4>Actions</H4>
        </Headline>
        {newPractices?.map((practice, index) => (
          <AdminPracticeStatusLine
            key={practice.id}
            practice={practice}
            index={newPractices?.length - index}
            selectPractice={() => handleSelectPractice(practice.id)}
            selected={selectedPractices.includes(practice.id)}
          />
        ))}
        <Date>Airtable Praxen</Date>

        <Headline>
          <Index></Index>
          <Index>Index</Index>
          <Date>Erstellt am</Date>
          <Name>Name</Name>
          <Date>Migrated</Date>
        </Headline>
        {migrationPractices?.map((practice, index) => (
          <AdminPracticeStatusLine
            key={practice.id}
            practice={practice}
            index={migrationPractices?.length - index}
            selectPractice={() => handleSelectPractice(practice.id)}
            selected={selectedPractices.includes(practice.id)}
          />
        ))}
      </Container>
      <Outlet />
    </FullScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const StyledH1 = styled(H1)``;

const Headline = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid ${colors.Light};
  align-items: center;
`;

const Date = styled(H4)`
  flex: 1;
  text-align: left;
`;

const Name = styled(H4)`
  flex: 2;
  text-align: left;
`;

const ActionMenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 10px;
`;

const ToolBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const SelectedPracticesActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const Index = styled(H4)`
  width: 50px;
  text-align: left;
`;

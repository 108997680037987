import { useEffect, useState } from 'react';
import { usePracticeSettingsConnect } from '../../../core/utils/connectFirestore/usePracticeSettingsConnect';
import { editPracticeSettings } from '../../../core/utils/editFirestore/editPracticeSettings';

export const useHandlePracticeSettings = (practiceId?: string) => {
  const { practiceSettings } = usePracticeSettingsConnect(practiceId);
  const [isSelfProfileCreationNotAllowed, setIsSelfProfileCreationNotAllowed] = useState<boolean>(
    practiceSettings?.practiceTeamSettings?.isSelfProfileCreationNotAllowed ?? false,
  );

  const toggleSelfProfileCreationAllowed = () => {
    console.log('toggleSelfProfileCreationAllowed');
    setIsSelfProfileCreationNotAllowed((prev) => !prev);
  };

  useEffect(() => {
    setIsSelfProfileCreationNotAllowed(
      practiceSettings?.practiceTeamSettings?.isSelfProfileCreationNotAllowed ?? false,
    );
  }, [practiceSettings]);

  const updateSettings = async () => {
    if (!practiceId) return;
    await editPracticeSettings(practiceId, {
      practiceTeamSettings: { isSelfProfileCreationNotAllowed },
    });
  };

  const saveOnToggle = async () => {
    if (!practiceId) return;
    await editPracticeSettings(practiceId, {
      practiceTeamSettings: { isSelfProfileCreationNotAllowed: !isSelfProfileCreationNotAllowed },
    });
  };

  return { isSelfProfileCreationNotAllowed, toggleSelfProfileCreationAllowed, updateSettings, saveOnToggle };
};
